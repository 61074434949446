$(document).ready(function ()
{

		/*
	 *  POPUP
	 */

	$('#popup').modal('show');


	// Youtube/Vimeo thumbs
	$("[class*='rc-video'] .thumb").click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			iframe.attr('src', src + '?autoplay=1');
		}
		block.fadeOut(150);
	}


	// Lightbox - UNCOMMENT ME BIJ KOPPELEN

	$('a.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true
	});


	/*
	 *	PIC BANNER
	 */

	$('.picbanner .link-block').click(function(e){
		if ($(window).width() > 991) {
			e.preventDefault();
			$('.picbanner .link-block').removeClass('active');
			$(this).addClass('active');

			var active_id = $(this).attr('data-id');
			$('.cta-blue').addClass('hidden');
			$('#'+active_id).removeClass('hidden');
		}
	});


	// Autoplay
	$('#play-cover a').click();
});
